import create from 'zustand';

interface MobileMenuState {
  // Get and set the state of mobile menu
  isMenuOpen: boolean;
  setIsMenuOpen: (boolean: boolean) => void;
}

export const useMobileMenuState = create<MobileMenuState>((set) => ({
  isMenuOpen: false,
  setIsMenuOpen: (boolean: boolean) => set({ isMenuOpen: boolean }),
}));
