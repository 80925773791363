import create from 'zustand';

interface NavigationState {
  hasAnimated: boolean;
  toggleHasAnimated: () => void;
}

export const useNavigationStore = create<NavigationState>((set) => ({
  hasAnimated: false,
  toggleHasAnimated: () => {
    set({
      hasAnimated: true,
    });
  },
}));
