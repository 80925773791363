import { Box } from '@/components/box';
import { GridContainer } from '@/components/grid-container';
import { useDebugStore } from '@/hooks/use-debug-store';
import { pinkA } from '@radix-ui/colors';
import { useCallback } from 'react';

export function DebugGrid() {
  const isGridVisible = useDebugStore(
    useCallback((state) => state.isGridVisible, [])
  );

  if (!isGridVisible) {
    return null;
  }

  return (
    <Box
      css={{
        position: 'fixed',
        zIndex: 99999999,
        inset: 0,
        pointerEvents: 'none',
      }}
    >
      <GridContainer>
        {[...Array(17)].map((_, index) => {
          return (
            <Box
              key={`grid-lines-${index}`}
              css={{
                height: '100vh',
                gridColumn: 'unset',
                background: pinkA.pinkA3,
                pointerEvents: 'none',
              }}
            />
          );
        })}
      </GridContainer>
    </Box>
  );
}
