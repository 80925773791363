import { styled } from '@/stitches.config';

type LogoProps = {
  className?: string;
  height?: number;
  width?: number;
  collapsed?: boolean;
};
/**
 * An inlined version of the SVG CLEVERªFRANKE logo
 * We inline it, because it gets returned in the DOM immediately from the server.
 * This reduces FCP and LCP core web vitals
 * @returns JSX SVG Logo
 */
export function Logo({
  className,
  height = 19,
  width = 193,
  collapsed = false,
}: LogoProps) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 193 19"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <defs>
        <clipPath id="clipPath1">
          <rect x="15" y="0" width="178" height="20" />
        </clipPath>
      </defs>
      <C
        d="M7.62703 16.1773C5.10986 16.1773 3.5576 14.2139 3.5576 10.3962C3.5576 6.70431 5.23572 4.47241 7.64381 4.47241C8.45105 4.45399 9.24114 4.70688 9.88765 5.19062C10.5342 5.67436 10.9997 6.36098 11.2098 7.14062L14.4402 6.30156C13.8948 4.46402 12.0573 1.77904 7.66899 1.77904C2.94509 1.74548 0 5.38699 0 10.4549C0 15.6822 2.51717 18.9378 7.62703 18.9378C12.3425 18.9378 14.1549 15.8752 14.6332 13.8447L11.2769 13.165C11.1387 14.025 10.6919 14.8051 10.0201 15.3595C9.34832 15.9139 8.4976 16.2046 7.62703 16.1773Z"
        fill="currentColor"
        className="C"
      />
      <g style={{ clipPath: 'url(#clipPath1)' }}>
        <HideablePath
          d="M20.9763 2.04736H17.6201V18.5432H28.4523V15.6317H20.9763V2.04736Z"
          fill="currentColor"
          collapsed={collapsed}
        />
        <HideablePath
          d="M42.6912 15.7408H34.6446V11.5203H41.2228V8.88568H34.6446V4.81625H42.4898V2.04736H31.3052V18.5432H42.6912V15.7408Z"
          fill="currentColor"
          collapsed={collapsed}
        />

        <HideablePath
          d="M55.1428 18.5432L60.8735 2.04736H57.7271L53.1122 15.2709L48.338 2.04736H45.1328L50.8636 18.5432H55.1428Z"
          fill="currentColor"
          collapsed={collapsed}
        />
        <HideablePath
          d="M74.7516 15.7408H66.7638V11.5455H73.3V8.88568H66.7638V4.81625H74.567V2.04736H63.4243V18.5432H74.7516V15.7408Z"
          fill="currentColor"
          collapsed={collapsed}
        />
        <HideablePath
          d="M84.1744 12.4936C84.2583 12.4936 84.7953 12.4936 84.9212 12.4432L87.8746 18.5432H91.6924L87.8914 11.6377C88.7636 11.2917 89.5157 10.6983 90.0553 9.93068C90.5949 9.16302 90.8985 8.25445 90.9288 7.3166C90.9288 4.93367 89.9052 3.27234 87.7404 2.5088C86.3921 2.04776 84.9682 1.84841 83.5451 1.92146C81.0783 1.92146 78.0073 2.00536 78.0073 2.00536V18.5432H81.3636V12.4936H84.1744ZM81.3887 4.64839C81.3887 4.64839 82.9074 4.58127 83.7381 4.58127C84.7706 4.46901 85.8103 4.71487 86.6832 5.27769C86.9628 5.53078 87.183 5.84254 87.328 6.19067C87.4731 6.5388 87.5394 6.91468 87.5222 7.29142C87.5515 7.74342 87.4499 8.19428 87.2297 8.59009C87.0095 8.98589 86.6799 9.30988 86.2804 9.52332C85.4414 9.93446 84.2583 9.93446 82.6809 9.93446H81.3887V4.64839Z"
          fill="currentColor"
          collapsed={collapsed}
        />
        <Degree
          d="M96.366 0C95.7022 0 95.0533 0.196839 94.5014 0.565627C93.9494 0.934414 93.5193 1.45859 93.2652 2.07186C93.0112 2.68513 92.9448 3.35995 93.0743 4.011C93.2038 4.66204 93.5234 5.26007 93.9928 5.72944C94.4622 6.19882 95.0602 6.51847 95.7112 6.64797C96.3623 6.77747 97.0371 6.71101 97.6504 6.45698C98.2636 6.20296 98.7878 5.77278 99.1566 5.22085C99.5254 4.66892 99.7222 4.02003 99.7222 3.35623C99.7222 2.4661 99.3686 1.61243 98.7392 0.983017C98.1098 0.353602 97.2561 0 96.366 0V0ZM96.366 4.75746C96.0163 4.73026 95.6898 4.57216 95.4517 4.31474C95.2135 4.05731 95.0812 3.71952 95.0812 3.36882C95.0812 3.01811 95.2135 2.68032 95.4517 2.4229C95.6898 2.16547 96.0163 2.00737 96.366 1.98018V1.98018C96.5574 1.96528 96.7499 1.99013 96.9312 2.05317C97.1126 2.1162 97.279 2.21605 97.4199 2.34645C97.5609 2.47686 97.6733 2.63499 97.7502 2.81093C97.8272 2.98686 97.8669 3.1768 97.8669 3.36882C97.8669 3.56083 97.8272 3.75077 97.7502 3.9267C97.6733 4.10264 97.5609 4.26078 97.4199 4.39118C97.279 4.52158 97.1126 4.62143 96.9312 4.68447C96.7499 4.7475 96.5574 4.77235 96.366 4.75746V4.75746Z"
          fill="currentColor"
          collapsed={collapsed}
        />
        <F
          d="M102.231 18.4842H105.57L105.562 11.5201L111.595 11.5285V8.79313L105.57 8.80991V4.85795L112.509 4.86634V1.97998H102.231V18.4842Z"
          fill="currentColor"
          className="F"
          collapsed={collapsed}
        />
        <HideablePath
          d="M124.969 11.5708C125.841 11.2247 126.593 10.6314 127.133 9.86374C127.673 9.09607 127.976 8.1875 128.007 7.24965C128.007 4.86673 126.975 3.20539 124.81 2.44185C123.462 1.9796 122.038 1.78022 120.614 1.85451C118.148 1.85451 115.438 1.93841 115.438 1.93841V18.4595H118.794V12.4266H121.445C121.633 12.4202 121.821 12.4034 122.007 12.3763L124.944 18.4595H128.77L124.969 11.5708ZM123.341 9.45637C122.502 9.86751 121.412 9.86751 119.843 9.86751H118.752V4.58145C118.752 4.58145 119.985 4.51432 120.824 4.51432C121.861 4.40187 122.904 4.65084 123.778 5.21913C124.059 5.46945 124.28 5.77952 124.425 6.12656C124.57 6.47359 124.636 6.84876 124.617 7.22448C124.645 7.68035 124.539 8.13456 124.313 8.53114C124.086 8.92772 123.749 9.24933 123.341 9.45637V9.45637Z"
          fill="currentColor"
          collapsed={collapsed}
        />
        <HideablePath
          d="M140.123 1.89648H135.844L130.474 18.4595H133.872L135.088 14.1887H140.626L141.843 18.4595H145.476L140.123 1.89648ZM135.877 11.5624L137.899 4.52273L139.913 11.5624H135.877Z"
          fill="currentColor"
          collapsed={collapsed}
        />
        <HideablePath
          d="M158.851 14.0624L152.465 1.97998H147.817V18.4842H151.123L151.005 5.99907L157.609 18.4842H162.123V1.97998H158.716L158.851 14.0624Z"
          fill="currentColor"
          collapsed={collapsed}
        />
        <HideablePath
          d="M178.929 1.97998H174.776L169.129 8.47429V1.97998H165.848V18.4842H169.129V12.7954L170.933 10.6978L175.674 18.4842H179.911L173.182 8.4659L178.929 1.97998Z"
          fill="currentColor"
          collapsed={collapsed}
        />
        <HideablePath
          d="M185.297 11.4529L191.532 11.4613V8.8183H185.306L185.297 4.74887L192.799 4.75726V1.97998H182V18.4842H192.992V15.6818H185.306L185.297 11.4529Z"
          fill="currentColor"
          collapsed={collapsed}
        />
      </g>
    </Svg>
  );
}

const Svg = styled('svg', {
  backfaceVisibility: 'hidden',
});

const C = styled('path', {
  transition: 'all 50ms ease-out',
});

const Degree = styled('path', {
  transition: 'all 50ms ease-out, transform 300ms ease',
  transform: 'translateX(0)',

  variants: {
    collapsed: {
      true: {
        transform: 'translateX(-75.5px)',
        transition: 'all 50ms ease-out, transform 250ms ease',
      },
    },
  },
});

const F = styled('path', {
  transition: 'all 50ms ease-out, transform 300ms ease',
  transform: 'translateX(0)',

  variants: {
    collapsed: {
      true: {
        transform: 'translateX(-74px)',
        transition: 'all 50ms ease-out, transform 250ms ease',
      },
    },
  },
});

const HideablePath = styled('path', {
  transition:
    'all 50ms ease-out, opacity 500ms ease-out 0s, transform 300ms ease',
  opacity: '1',
  transform: 'translateX(0)',

  variants: {
    collapsed: {
      true: {
        opacity: 0,
        transform: 'translateX(-74px)',
        transition:
          'all 50ms ease-out, opacity 75ms ease-in, transform 250ms ease',
      },
    },
  },
});
