import 'keen-slider/keen-slider.min.css';
import { CookiePopup } from '@/components/cookie-popup';
import { DebugGrid } from '@/components/debug-grid';
import { ErrorBoundary } from '@/components/error-boundary';
import { Header } from '@/components/header/header';
import { darkTheme, globalStyles } from '@/stitches.config';
import { MotionConfig } from 'framer-motion';
import { ThemeProvider } from 'next-themes';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { Analytics } from '@vercel/analytics/react';
import { GoogleTag } from '@/components/google-tag';
import { useEffect, useRef } from 'react';

globalStyles();

// This default export is required in a new `pages/_app.js` file.
export default function MyApp(props: AppProps) {
  const { Component, pageProps } = props;

  const router = useRouter();
  const previousPath = useRef<string>('');

  // Disables scroll restoration for the site due to it causing issues referenced here: https://github.com/vercel/next.js/issues/3303
  if (typeof window !== 'undefined') {
    window.history.scrollRestoration = 'manual';
  }

  useEffect(() => {
    const handleSettingPreviousPath = () => {
      previousPath.current = router.pathname;
    };

    router.events.on('routeChangeStart', handleSettingPreviousPath);

    return () => {
      router.events.off('routeChangeStart', handleSettingPreviousPath);
    };
  }, [router.pathname, router.events]);

  return (
    <>
      <GoogleTag />

      <MotionConfig reducedMotion="user">
        <ThemeProvider
          attribute="class"
          defaultTheme="system"
          value={{
            dark: darkTheme.toString(),
            light: 'light',
          }}
        >
          <ErrorBoundary>
            <Header previousPath={previousPath.current} />
            <Component {...pageProps} />
            <DebugGrid />
          </ErrorBoundary>

          <CookiePopup />
        </ThemeProvider>
      </MotionConfig>
      <Analytics />
    </>
  );
}
