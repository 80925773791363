import { styled } from '@/stitches.config';
import { motion } from 'framer-motion';

export const Bar = styled(motion.div, {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',

  backgroundColor: '$background',
  transition: 'background 0.3s ease-in-out',
  borderBottom: '1px solid $gray4',
});
