import { styled } from '@/stitches.config';

export const Text = styled('span', {
  margin: 0,
  fontFamily: '$neutral',
  variants: {
    size: {
      1: {
        fontSize: '$1',
        lineHeight: '$7',
        mb: '$5',
      },
      2: {
        fontSize: '$2',
        fontWeight: '$normal',
        lineHeight: '$7',
        mb: '$6',
      },
      3: {
        fontSize: '$3',
        lineHeight: '$7',
        fontWeight: '$normal',
        mb: '$6',
      },
      4: {
        // h5
        fontSize: '$4',
        lineHeight: '$4',
        fontWeight: '$normal',
        mb: '$1',
      },
      5: {
        // h4 / big text
        fontSize: '$6',
        fontWeight: '$normal',
        lineHeight: '$4',
        mb: '$6',
      },
      6: {
        // h3
        fontSize: '$7',
        fontWeight: '$normal',
        lineHeight: 1.35,
      },
      8: {
        // h2
        fontSize: '$8',
        fontWeight: '$normal',
        lineHeight: '$2',
        mb: '$5',
      },
      11: {
        // h1, very large text
        fontSize: '$9',
        fontWeight: '$normal',
        lineHeight: '$2',
        mb: '$7',
      },
      12: {
        fontSize: '$10',
        fontWeight: '$normal',
        lineHeight: '$2',
        mb: '$7',
      },
      13: {
        fontSize: '$11',
        fontWeight: '$normal',
        lineHeight: '$2',
        mb: '$7',
      },
      14: {
        fontSize: '$12',
        fontWeight: '$normal',
        lineHeight: '$2',
        mb: '$7',
      },
      15: {
        fontSize: '$13',
        fontWeight: '$normal',
        lineHeight: '$2',
        letterSpacing: '-0.005em',
        mb: '$7',
      },
      16: {
        fontSize: '$14',
        fontWeight: '$normal',
        lineHeight: '$2',
        letterSpacing: '-0.005em',
        mb: '$7',
      },
      17: {
        // this has some custom styles outside of the theme because it is such a big text
        fontSize: 140 / 15 + 'em',
        fontWeight: '$normal',
        lineHeight: 1.165,
        letterSpacing: '-0.01em',
        mt: '$8',
        mb: '$8',
      },
    },
  },
});

export const UnresponsiveCapsizedText = styled('span', {
  marginBottom: 0,
  fontFamily: '$neutral',
  variants: {
    size: {
      1: { capSize: 1 },
      2: { capSize: 2 },
      3: { capSize: 3 },
      4: { capSize: 4 },
      5: { capSize: 5 },
      6: { capSize: 6 },
      7: { capSize: 7 },
      8: { capSize: 8 },
      9: { capSize: 9 },
      10: { capSize: 10 },
      11: { capSize: 11 },
    },
  },
});

UnresponsiveCapsizedText.displayName = 'UnresponsiveCapsizedText';

export const CapsizedText = styled('span', {
  marginBottom: 0,
  fontFamily: '$neutral',

  variants: {
    size: {
      1: { capSize: 1 },
      2: { capSize: 2 },
      3: { capSize: 3 },
      4: { capSize: 4 },
      5: {
        capSize: 5,

        '@bp3': {
          capSize: 6,
        },
      },
      6: {
        capSize: 7,

        '@bp3': {
          capSize: 8,
        },
      },
      7: {
        capSize: 8,

        '@bp2': {
          capSize: 9,
        },

        '@bp3': {
          capSize: 10,
        },
      },
      8: {
        capSize: 7,

        '@bp2': {
          capSize: 8,
        },

        '@bp3': {
          capSize: 9,
          marginLeft: '-0.1ch',
        },

        '@bp4': {
          capSize: 11,
        },
      },
      9: {
        capSize: 9,
      },
      10: {
        capSize: 10,
      },
      11: {
        capSize: 11,
      },
    },

    weight: {
      thin: { fontWeight: '$thin' },
      normal: { fontWeight: '$normal' },
      heavy: { fontWeight: '$heavy' },
    },
  },
  defaultVariants: {
    size: 4,
    weight: 'normal',
  },
});

CapsizedText.displayName = 'CapsizedText';
