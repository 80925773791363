import { styled } from '@/stitches.config';

export const NavIconBar = styled('span', {
  $$offset: '3.75px',
  display: 'block',
  backgroundColor: 'currentColor',
  height: '2px',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-$$offset) translateZ(0)',
  transition: 'transform 0.35s ease',
  width: '100%',
  '&:last-child': {
    transform: 'translateY($$offset) translateZ(0)',
  },
  variants: {
    invert: {
      true: {
        backgroundColor: '$foreground',
      },
    },
  },
});
