import { createStyleObject } from '@capsizecss/core';
import {
  blackA,
  blue,
  crimson,
  gray,
  grayDark,
  green,
  indigo,
  red,
  violet,
  yellow,
} from '@radix-ui/colors';
// eslint-disable-next-line no-restricted-imports -- This is the one file we're allowed to import from Stitches directly, because we expose the functions through this file
import type * as Stitches from '@stitches/react';
import { createStitches } from '@stitches/react';

export { keyframes } from '@stitches/react';

export const normalFontMetrics = {
  capHeight: 755,
  ascent: 778,
  descent: -222,
  lineGap: 200,
  unitsPerEm: 1000,
};

/**
 * I can't use the fontSizes directly. That's a shame. Capsize is expecting unitless numbers.
 * Our theme is using rems, and thus those values are not compatible.
 * This means we will have to copy all changes to fontSizes and translate them to unitless pixel values
 * in fontSizesForCapSize. It's a pain, but... it works.
 */
export const fontSizesForCapSize = {
  1: { ch: 11, fs: 13, lg: 12 },
  2: { ch: 12, fs: 15, lg: 14 },
  3: { ch: 15, fs: 17, lg: 15 },
  4: { ch: 17, fs: 22, lg: 18 },
  5: { ch: 23, fs: 30, lg: 21 },
  6: { ch: 30, fs: 40, lg: 27 },
  7: { ch: 38, fs: 50, lg: 32 },
  8: { ch: 54, fs: 70, lg: 36 },
  9: { ch: 68, fs: 90, lg: 38 },
  10: { ch: 76, fs: 100, lg: 38 },
  11: { ch: 90, fs: 108, lg: 38 },
} as const;

const roundDecimals = (value: number, decimals: number) => {
  const d = Math.pow(10, decimals);
  return Math.round(value * d) / d;
};

const fontSizes = {
  1: roundDecimals(1 / 1.2 ** 1, 4) + 'em', // 13px
  2: roundDecimals(1 * 1.2 ** 0, 1) + 'em', // 15px (base font-size)
  3: roundDecimals(1 * 1.2 ** 1, 1) + 'em', // 18px
  4: roundDecimals(1 * 1.2 ** 2, 1) + 'em', // 22px
  5: roundDecimals(1 * 1.2 ** 3, 1) + 'em', // 26px
  6: roundDecimals(1 * 1.2 ** 4, 1) + 'em', // 31px
  7: roundDecimals(1 * 1.2 ** 5, 1) + 'em', // 37px
  8: roundDecimals(1 * 1.2 ** 6, 1) + 'em', // 45px
  9: roundDecimals(1 * 1.2 ** 7, 1) + 'em', // 54px
  10: roundDecimals(1 * 1.2 ** 8, 1) + 'em', // 64px
  11: roundDecimals(1 * 1.2 ** 9, 1) + 'em', // 77px
  12: roundDecimals(1 * 1.2 ** 10, 1) + 'em', // 93px
  13: roundDecimals(1 * 1.2 ** 11, 1) + 'em', // 111px
  14: roundDecimals(1 * 1.2 ** 12, 1) + 'em', // 134px
};

export const media = {
  bp1: '(min-width: 30em)', // 480
  bp2: '(min-width: 40em)', // 640
  bp3: '(min-width: 52.5em)', // 840
  bp4: '(min-width: 64em)', // 1024
  bp5: '(min-width: 73.75em)', // 1180
  bp6: '(min-width: 80em)', // 1280
  bp7: '(min-width: 90em)', // 1440
  bp8: '(min-width: 120em)', // 1920

  motion: '(prefers-reduced-motion: no-preference)',
  hover: '(any-hover: hover)',
  dark: '(prefers-color-scheme: dark)',
  noHover: '(hover: none)',
};

export type CSS = Stitches.CSS<typeof config>;

const getFontSizeInEm = (fontSizeInPx: string) =>
  parseInt(fontSizeInPx.replace('px', '')) / 16 + 'em';

const getLineHeightInRatio = (fontSizeInPx: string, lineHeightInPx: string) => {
  const fz = parseInt(fontSizeInPx.replace('px', ''));
  const lh = parseInt(lineHeightInPx.replace('px', ''));
  return lh / fz;
};

export const {
  theme,
  css,
  styled,
  globalCss,
  getCssText,
  createTheme,
  config,
} = createStitches({
  utils: {
    capSize: (value: keyof typeof fontSizesForCapSize) => {
      const { fontSize, lineHeight, ...pseudo } = createStyleObject({
        fontMetrics: normalFontMetrics,
        fontSize: fontSizesForCapSize[value].fs,
        lineGap: fontSizesForCapSize[value].lg,
      });

      return {
        fontSize: getFontSizeInEm(fontSize),
        lineHeight: getLineHeightInRatio(fontSize, lineHeight),
        '&::before': pseudo['::before'],
        '&::after': pseudo['::after'],
      };
    },
    // textStyle: (value: keyof typeof textStyles) => textStyles[value],
    m: (value: Stitches.PropertyValue<'margin'>) => ({
      margin: value,
    }),
    mt: (value: Stitches.PropertyValue<'margin'>) => ({
      marginTop: value,
    }),
    mr: (value: Stitches.PropertyValue<'margin'>) => ({
      marginRight: value,
    }),
    mb: (value: Stitches.PropertyValue<'margin'>) => ({
      marginBottom: value,
    }),
    ml: (value: Stitches.PropertyValue<'margin'>) => ({
      marginLeft: value,
    }),
    mx: (value: Stitches.PropertyValue<'margin'>) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (value: Stitches.PropertyValue<'margin'>) => ({
      marginTop: value,
      marginBottom: value,
    }),

    pt: (value: Stitches.PropertyValue<'padding'>) => ({
      paddingTop: value,
    }),
    pr: (value: Stitches.PropertyValue<'padding'>) => ({
      paddingRight: value,
    }),
    pb: (value: Stitches.PropertyValue<'padding'>) => ({
      paddingBottom: value,
    }),
    pl: (value: Stitches.PropertyValue<'padding'>) => ({
      paddingLeft: value,
    }),
    px: (value: Stitches.PropertyValue<'padding'>) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: (value: Stitches.PropertyValue<'padding'>) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
  },
  theme: {
    colors: {
      ...gray,
      ...blue,
      ...red,
      ...green,
      ...indigo,
      ...violet,
      ...crimson,
      ...blackA,
      ...yellow,
      background: 'white',
      foreground: 'black',
      borderColor: 'rgba(0, 0, 0, 0.2)',
    },
    space: {
      0: 0,
      1: '0.25rem',
      2: '0.5rem',
      3: '0.75rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      7: '2rem',
      8: '2.5rem',
      9: '3rem',
      10: '3.5rem',
      11: '5rem',
      12: '7.5rem',
      13: '15rem',
    },
    sizes: {
      0: 0,
      1: '0.25rem', // 4px
      2: '0.5rem', // 8px
      3: '0.75rem', // 12px
      4: '1rem', // 16px
      5: '1.25rem', // 20px
      6: '1.5rem', // 24px
      7: '2rem', // 32px //
      8: '2.5rem', // 40px
      9: '5rem', // 80px // SMALL
      10: '7.5rem', // 120px //MEDIUM
      11: '15rem', // 240px
    },
    fontSizes,
    fonts: {
      neutral: 'Neutral, sans-serif',
    },
    fontWeights: {
      thin: '300',
      normal: '400',
      heavy: '500',
    },
    zIndices: {
      default: 1,
      textOverlay: 2,
      header: 4,
      overlay: 5,
      expandedItem: 6,
      iconOverlay: 7,
    },
    lineHeights: {
      1: 1,
      2: 1.175,
      3: 1.2,
      4: 1.3,
      5: 1.4,
      6: 1.6,
      7: 1.67,
    },
  },
  media,
});

export const darkTheme = createTheme({
  colors: {
    background: 'black',
    foreground: '#f5f5f5',
    borderColor: '#f5f5f580',
    ...grayDark,
  },
});

export const globalStyles = globalCss({
  html: {
    boxSizing: 'border-box',
    fontSize: '93.75%', // base font size of 15px
    scrollPaddingTop: 'calc(74px + $8)',
  },

  '*': { boxSizing: 'inherit' },
  '*::before': { boxSizing: 'inherit' },
  '*::after': { boxSizing: 'inherit' },

  body: {
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    background: '$background',
    color: '$foreground',
    fontFamily: '$neutral',
    fontSize: '1em',
    lineHeight: '$5',
    margin: 0,
    transition: 'background 0.25s ease-in-out',
  },

  /* Prevent images overflowing from grid or flex containers */
  'img,svg,video,canvas': {
    display: 'block',
    maxWidth: '100%',
  },

  'blockquote,dl,dd,h1,h2,h3,h4,h5,h6,hr,figure,p,pre': { margin: 0 },

  'ul,ol': {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },

  input: {
    '&:focus-visible': {
      backgroundColor: 'rgb(255 255 255 / 15%)', // footer is always black
    },
  },

  button: {
    all: 'unset',
    cursor: 'pointer',
  },

  '.disable-scroll': {
    overflow: 'hidden',
  },

  // prevent FOUC
  '@dark': {
    // notice the `media` definition on the stitches.config.ts file
    ':root:not(.light)': {
      ...Object.keys(darkTheme.colors).reduce((varSet, currentColorKey) => {
        const currentColor =
          darkTheme.colors[currentColorKey as keyof typeof darkTheme['colors']];
        const currentColorValue =
          currentColor.value.substring(0, 1) === '$'
            ? `$colors${currentColor.value}`
            : currentColor.value;

        return {
          [currentColor.variable]: currentColorValue,
          ...varSet,
        };
      }, {}),
    },
  },
});
