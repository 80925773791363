import { HeaderContainer } from '../shared/header-container';
import { LogoWrapper } from '../shared/logo-wrapper';
import Link from 'next/link';
import { LogoAnchor } from '../shared/logo-anchor';
import { Logo } from '@/components/logo';
import { Bar } from '../shared/bar';
import { duration, Navigation } from '@/components/navigation';
import { motion, type AnimationControls, AnimatePresence } from 'framer-motion';
import { styled } from '@/stitches.config';

type DesktopHeaderProps = {
  isTouch: boolean;
  setHoveringHeader: (setHeader: boolean) => void;
  barControls: AnimationControls;
  desktopNavControls: AnimationControls;
  logoClickable: boolean;
  shouldInvertUi: boolean;
  isLogoCollapsed: boolean;
  hasNav: boolean;
  headerWidth: string;
  isPreviousRouteHome: boolean;
};

export function DesktopHeader({
  isTouch,
  setHoveringHeader,
  barControls,
  desktopNavControls,
  logoClickable,
  shouldInvertUi,
  isLogoCollapsed,
  hasNav,
  headerWidth,
  isPreviousRouteHome,
}: DesktopHeaderProps) {
  return (
    <>
      <HeaderContainer
        onMouseEnter={() => !isTouch && setHoveringHeader(true)}
        onMouseLeave={() => !isTouch && setHoveringHeader(false)}
        css={{ width: headerWidth }}
      >
        <Bar style={{ opacity: 0, y: -100 }} animate={barControls} />

        <LogoWrapper>
          {/* Logo */}
          <Link href="/" passHref>
            <LogoAnchor
              enabled={logoClickable}
              aria-label="Company logo"
              invert={shouldInvertUi}
            >
              <Logo collapsed={isLogoCollapsed} />
            </LogoAnchor>
          </Link>
        </LogoWrapper>

        {/* Desktop nav */}
        <AnimatePresence>
          {hasNav ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { delay: isPreviousRouteHome ? duration : 0 },
              }}
              exit={{ opacity: 0, transition: { duration: duration * 0.25 } }}
            >
              <DesktopNavContainer animate={desktopNavControls}>
                <Navigation invert={shouldInvertUi} />
              </DesktopNavContainer>
            </motion.div>
          ) : null}
        </AnimatePresence>
      </HeaderContainer>
    </>
  );
}

const DesktopNavContainer = styled(motion.div, {
  display: 'none',
  '@bp3': {
    display: 'block',
  },
});
