import { useEffect, useState } from 'react';

export const useScrollThreshold = (threshold: number) => {
  const [hasPassedThreshold, setPassedThreshold] = useState(false);

  useEffect(() => {
    let rafId: number;
    let ticking = false;

    const checkThreshold = () => {
      const y = window.pageYOffset;
      const check = y > threshold;
      if (check !== hasPassedThreshold) {
        setPassedThreshold(check);
      }

      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        rafId = window.requestAnimationFrame(checkThreshold);
        ticking = true;
      }
    };

    window.addEventListener('scroll', onScroll);

    rafId = window.requestAnimationFrame(checkThreshold);
    ticking = true;

    return () => {
      window.cancelAnimationFrame(rafId);
      window.removeEventListener('scroll', onScroll);
    };
  }, [threshold, hasPassedThreshold]);

  return hasPassedThreshold;
};
