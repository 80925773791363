import { Box } from '@/components/box';
import { HeaderContainer } from '@/components/header/shared/header-container';
import { Logo } from '@/components/logo';
import { NavModal } from '@/components/navigation/modal';
import { useEffect } from 'react';
import { Bar } from '@/components/header/shared/bar';
import { useRouter } from 'next/router';
import Link from 'next/link';

import type { AnimationControls } from 'framer-motion';

import { LogoAnchor } from '@/components/header/shared/logo-anchor';
import { useMobileMenuState } from '@/hooks/use-mobile-menu-state';

type MobileHeaderProps = {
  logoCollapsed: boolean;
  barControls: AnimationControls;
  logoClickable: boolean;
  shouldInvertUi: boolean;
  headerWidth: string;
};

export function MobileHeader({
  logoCollapsed,
  barControls,
  logoClickable,
  shouldInvertUi,
  headerWidth,
}: MobileHeaderProps) {
  const { isMenuOpen, setIsMenuOpen } = useMobileMenuState();

  const router = useRouter();

  const showNavMenu = router.route !== '/';

  useEffect(() => {
    const handleRouteChange = () => {
      if (isMenuOpen) {
        setIsMenuOpen(false);
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted or the open prop changes unsubscribe
    // from the event with the `off` method and subscribe again with updated values
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, isMenuOpen, setIsMenuOpen]);

  return (
    <HeaderContainer css={{ width: headerWidth }}>
      <Bar style={{ opacity: 0, y: -100 }} animate={barControls} />

      <Box
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '$4',
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Link href="/" passHref>
          <LogoAnchor
            enabled={logoClickable}
            aria-label="Company logo"
            invert={shouldInvertUi}
          >
            <Logo collapsed={logoCollapsed} />
          </LogoAnchor>
        </Link>
        {showNavMenu && (
          <NavModal
            open={isMenuOpen}
            setOpen={setIsMenuOpen}
            shouldInvertMenuIcon={shouldInvertUi}
          />
        )}
      </Box>
    </HeaderContainer>
  );
}
