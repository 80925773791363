import { useEffect, useRef, useState } from 'react';

export const SCROLL_UP = 'up';
export const SCROLL_DOWN = 'down';

type ScrollProps = {
  initialDirection?: typeof SCROLL_UP | typeof SCROLL_DOWN;
  thresholdPixels?: number;
};

export const useScrollDirection = ({
  initialDirection = undefined,
  thresholdPixels = 60,
}: ScrollProps = {}) => {
  const [scrollDirection, setScrollDir] = useState(initialDirection);
  const scrollY = useRef(0);

  useEffect(() => {
    let rafId: number;
    const threshold = thresholdPixels || 0;
    let ticking = false;

    let lastScrollY = window.pageYOffset;

    const updateScrollDir = () => {
      scrollY.current = window.pageYOffset;

      if (Math.abs(scrollY.current - lastScrollY) < threshold) {
        // We haven't exceeded the threshold
        ticking = false;
        return;
      }

      const newScrollDirection =
        scrollY.current >= lastScrollY ? SCROLL_DOWN : SCROLL_UP;
      if (scrollDirection !== newScrollDirection) {
        setScrollDir(newScrollDirection);
      }

      lastScrollY = scrollY.current > 0 ? scrollY.current : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        rafId = window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.cancelAnimationFrame(rafId);
      window.removeEventListener('scroll', onScroll);
    };
  }, [initialDirection, thresholdPixels, scrollDirection]);

  return { scrollDirection };
};
