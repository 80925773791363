import create from 'zustand';

interface HeaderStore {
  isIntersectingWithProjectCover: boolean;
  setIsIntersectingWithProjectCover: (isIntersecting: boolean) => void;
}

export const useHeaderStore = create<HeaderStore>((set) => ({
  isIntersectingWithProjectCover: false,
  setIsIntersectingWithProjectCover: (isIntersecting) =>
    set({ isIntersectingWithProjectCover: isIntersecting }),
}));
