import {
  GA4_TRACKING_ID,
  GA_TRACKING_ID,
  GTM_ID,
  pageview as gtagPageview,
} from '@/lib/gtag';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useRef } from 'react';
import { getCookieConsentValue } from 'react-cookie-consent';

export function GoogleTag() {
  const router = useRouter();
  const firstPageLoadTracked = useRef(false);

  const cookieConsent = getCookieConsentValue();

  //Handle initial pageview
  useEffect(() => {
    if (firstPageLoadTracked.current === false) {
      gtagPageview(router.asPath);
      firstPageLoadTracked.current = true;
    }
  }, [router.asPath]);

  useEffect(() => {
    const handleRouteChangeComplete = (url: string) => {
      gtagPageview(url);
    };

    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router.events]);

  useEffect(() => {
    if (cookieConsent === 'true') {
      gtag('consent', 'update', {
        ad_storage: 'granted',
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        analytics_storage: 'granted',
      });
    } else if (cookieConsent === 'false') {
      gtag('consent', 'update', {
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        analytics_storage: 'granted',
      });
    }
  }, [cookieConsent]);

  return (
    <>
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('consent', 'default', {
                    'ad_storage': 'denied',
                    'ad_user_data': 'denied',
                    'ad_personalization': 'denied',
                    'analytics_storage': 'granted'
                })

                gtag('config', '${GA_TRACKING_ID}', {
                    page_path: window.location.pathname,
                    cookie_flags: 'SameSite=None;Secure'
                });
                gtag('config', '${GA4_TRACKING_ID}', {
                    page_path: window.location.pathname,
                    cookie_flags: 'SameSite=None;Secure'
                });
            `,
        }}
      />
      {/* Google Tag Manager */}
      <Script
        id="google-tag-manager"
        type="text/javascript"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${GTM_ID}');`,
        }}
      />
      {/* End Google Tag Manager */}
    </>
  );
}
