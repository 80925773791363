import { styled } from '@/stitches.config';
import { NavIconBar } from './nav-icon-bar';

export const NavToggle = styled('span', {
  display: 'block',
  height: '$5',
  margin: 0,
  padding: 0,
  position: 'relative',
  transition: 'transform 1s cubic-bezier(0.23, 1, 0.32, 1)',
  willChange: 'transform',
  width: '$5',
  '@bp3': {
    display: 'none',
  },

  variants: {
    opened: {
      true: {
        [`& ${NavIconBar}`]: {
          transform: 'rotate(45deg) translateZ(0)',
          '&:last-child': {
            transform: 'rotate(-45deg) translateZ(0)',
          },
        },
      },
    },
  },
});
