import * as React from 'react';

interface ErrorBoundaryProps {
  children: React.ReactNode;
  suppressError?: boolean;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(props: ErrorBoundaryProps) {
    // Update state so the next render will show the fallback UI.
    console.error(props);
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // You can also log the error to an error reporting service
    console.group();
    console.error(error);
    console.info(errorInfo);
    console.groupEnd();
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      if (this.props.suppressError) {
        return null;
      }
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
