import { styled } from '@/stitches.config';
import * as Dialog from '@radix-ui/react-dialog';
import { keyframes } from '@stitches/react';
import { Box } from '@/components/box';
import { ModalNavigation } from '@/components/navigation';
import { NavIconBar } from '../header/shared/nav-icon-bar';
import { NavToggle } from '../header/shared/nav-toggle';

import { useTheme } from 'next-themes';

type NavModalProps = {
  open: boolean;
  setOpen: (boolean: boolean) => void;
  shouldInvertMenuIcon: boolean;
};

export function NavModal({
  open,
  setOpen,
  shouldInvertMenuIcon,
}: NavModalProps) {
  const { resolvedTheme } = useTheme();

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>
        <Box
          as="button"
          css={{
            position: 'absolute',
            top: 0,
            right: 0,
            padding: '$4',
            color:
              resolvedTheme === 'light' && shouldInvertMenuIcon
                ? '$background'
                : '$foreground',
            '&:focus-visible': {
              outline: '1px solid currentColor',
              outlineOffset: '-4px',
            },
          }}
        >
          <NavToggle
            aria-label="Toggle navigation"
            onClick={() => setOpen(!open)}
            opened={open}
          >
            <NavIconBar invert={open} />
            <NavIconBar invert={open} />
          </NavToggle>
        </Box>
      </Dialog.Trigger>

      <Dialog.Portal>
        <Overlay>
          <Content
            css={{
              backgroundColor:
                resolvedTheme === 'light'
                  ? 'rgba(255,255,255,0.7)'
                  : 'rgba(0,0,0,0.7)',
              backdropFilter:
                resolvedTheme === 'light'
                  ? 'grayscale(1) contrast(100%) blur(1em) contrast(100%)'
                  : 'grayscale(1) contrast(100%) blur(1em) contrast(100%) invert(1)',
            }}
          >
            <Dialog.DialogClose asChild>
              {/* invisible button */}
              <Box
                as="button"
                css={{
                  position: 'fixed',
                  top: 0,
                  right: 0,
                  padding: '$4',
                  width: '20px',
                  height: '20px',
                  '&:focus-visible': {
                    outline: '1px solid currentColor',
                    outlineOffset: '-4px',
                  },
                }}
              ></Box>
            </Dialog.DialogClose>
            <Box
              css={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
                minHeight: '100%',
                padding: '$4',
                width: '100%',
                '@bp1': {
                  alignItems: 'center',
                },
              }}
            >
              <ModalNavigation />
            </Box>
          </Content>
        </Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

// slide in from top sequence
const contentShow = keyframes({
  '0%': { top: '-100%' },
  '100%': { top: 0 },
});

// slide out from bottom sequence
const contentHide = keyframes({
  '0%': { top: 0 },
  '100%': { top: '-100%' },
});

const Content = styled(Dialog.Content, {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  overflowY: 'auto',
  width: '100%',
  '&[data-state=open]': {
    animation: `${contentShow} 500ms ease-out`,
  },
  '&[data-state=closed]': {
    animation: `${contentHide} 300ms ease-in`,
  },
});

const Overlay = styled(Dialog.Overlay, {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'grid',
  placeItems: 'center',
  zIndex: 1,
  overflow: 'hidden',
  height: '100%',
  '&[data-state=open]': {
    animation: `${contentShow} 500ms ease-out`,
  },
  '&[data-state=closed]': {
    animation: `${contentHide} 300ms ease-in`,
  },
});
