import { styled } from '@/stitches.config';

export const HeaderContainer = styled('header', {
  position: 'fixed',
  top: 0,
  right: 0,
  left: 0,
  zIndex: '$header',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  '@bp3': {
    flexDirection: 'row',
    padding: '$6',
  },
  '@bp4': {
    padding: '$6 $8',
  },
});
