import { styled } from '@/stitches.config';

export const LogoAnchor = styled('a', {
  color: '$foreground',
  variants: {
    invert: {
      true: {
        color: '$background',
      },
    },
    enabled: {
      false: {
        cursor: 'auto',
        pointerEvents: 'none',
      },
    },
  },
});
