import { CSS, styled } from '@/stitches.config';
import { keyframes } from '@stitches/react';

type Props = {
  nestedDesktopNav?: boolean;
  inquiryBlock?: boolean;
  css?: CSS;
};

export function Underline(props: Props) {
  const { nestedDesktopNav = false, css } = props;
  return (
    <Root css={css}>
      <UnderLineLeft nestedDesktopNav={nestedDesktopNav} />
      <UnderLineRight nestedDesktopNav={nestedDesktopNav} />
    </Root>
  );
}

export const focusStateStyles = {
  outlineStyle: 'dashed',
  outlineWidth: '1px',
  outlineColor: 'currentColor',
  outlineOffset: '-2px',
};

// this animation makes the final underline height 2px
const slideUp = keyframes({
  '0%': {
    transformOrigin: 'bottom',
    transform: 'translate(0, 0)',
  },
  '70%': {
    transformOrigin: 'top',
    transform: 'translate(0, -4px)',
  },
  '100%': {
    transformOrigin: 'top',
    transform: 'translate(0, -4px) scaleY(0.5)',
  },
});

// this animation makes the final underline height 1px
const slideUpThinner = keyframes({
  '0%': {
    transformOrigin: 'bottom',
    transform: 'translate(0, 0)',
  },
  '70%': {
    transformOrigin: 'top',
    transform: 'translate(0, -4px)',
  },
  '100%': {
    transformOrigin: 'top',
    transform: 'translate(0, -4px) scaleY(0.25)',
  },
});

const UnderLineLeft = styled('div', {
  position: 'absolute',
  bottom: '-4px',
  left: 0,

  height: '4px',
  width: '50%',
  backgroundColor: 'currentColor',

  transition: 'transform',

  '@bp1': {
    bottom: '-4px',
  },

  '@bp3': {
    bottom: '-4px',
    transition: 'transform',
  },

  variants: {
    nestedDesktopNav: {
      true: {
        bottom: '-8px',
      },
    },
  },
});

const UnderLineRight = styled('div', {
  position: 'absolute',
  bottom: '-4px',
  right: 0,

  height: '4px',
  width: 'calc(50% + 1px)', // ensures we don't have a gap between the two underlines, sometimes it creeps in
  backgroundColor: 'currentColor',

  transition: 'transform',
  animationDelay: '100ms',

  variants: {
    nestedDesktopNav: {
      true: {
        bottom: '-8px',
      },
    },
  },
});

const Root = styled('div');

export const underlineHoverStyles = {
  animationName: `${slideUp}`,
  animationDuration: '250ms',
  animationTimingFunction: 'ease-in',
  animationFillMode: 'forwards',
};

export const thinnerUnderlineHoverStyles = {
  ...underlineHoverStyles,
  animationName: `${slideUpThinner}`,
};

Underline.toString = () => Root.toString();
